import axios from "axios";
import { saveAs } from "file-saver";
import dayjs from "dayjs";

const endpoint = process.env.NEXT_PUBLIC_ASSETS_ENDPOINT + "/Truck";

export async function fetchReadyForReview() {
  const response = await axios.get(`${endpoint}/fetchReadyForReview`);

  return response.data;
}

export async function fetchRecent() {
  const response = await axios.get(`${endpoint}/fetchRecent`);

  return response.data;
}

export async function getDetails(vin) {
  let responseData = null;

  try {
    const response = await axios.get(`${endpoint}/${vin}`);

    responseData = response.data;
  } catch (err) {
    responseData = err.response;
  }

  return responseData;
}

export async function updateDetails(vin, isWholesale) {
  await axios.put(`${endpoint}/${vin}`, { vin, isWholesale });
}

export async function deleteImages(vin, assetIds) {
  const response = await axios.delete(`${endpoint}/${vin}/assets`, {
    params: { assetIds: assetIds.join(",") },
  });

  return response;
}

export async function generateFeaturedImage(vin) {
  await axios.post(`${endpoint}/${vin}/assets/generate`);
}

export async function downloadImages(vin, assetIds) {
  const timeStamp = dayjs().format("YY-MM-DD_HH-mm-ss");

  const response = await axios.get(`${endpoint}/${vin}/assets/download`, {
    responseType: "blob",
    params: {
      assetIds: assetIds.join(","),
    },
  });

  saveAs(response.data, `${vin}-${timeStamp}.zip`);

  return "successfully downloaded";
}

export async function approveImage(vin, assetId) {
  const response = await axios.get(
    `${endpoint}/${vin}/assets/${assetId}/markAsReviewed`
  );

  return response;
}

export async function showImage(vin, assetId) {
  const response = await axios.get(`${endpoint}/${vin}/assets/${assetId}`);

  return response.data;
}

export async function updateImageDetails(vin, assetId, assetDetails) {
  const response = await axios.put(`${endpoint}/${vin}/assets/${assetId}`, {
    ...assetDetails,
  });

  return response;
}

export async function bulkUpdateImageDetails(vin, assetDetails) {
  const response = await axios.put(`${endpoint}/${vin}/assets`, assetDetails);

  return response;
}

export async function deleteImage(vin, assetId) {
  const response = await axios.delete(`${endpoint}/${vin}/assets/${assetId}`);

  return response.data;
}

export async function reorderImages(vin, assetIds) {
  const response = await axios.put(`${endpoint}/${vin}/assets/sort`, assetIds);

  return response;
}

export async function createTruckRecord(assetDetails) {
  const response = await axios.post(
    `${process.env.NEXT_PUBLIC_ASSETS_ENDPOINT}/Truck`,
    {
      ...assetDetails,
    }
  );

  return response;
}

export async function getPhotoContainers() {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_ASSETS_ENDPOINT}/Scaffolding`
  );

  return response?.data?.photos;
}

export async function getBranches() {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_ASSETS_ENDPOINT}/Branch`
  );

  return response?.data;
}

export async function syncTruckToWebsite(vin) {
  const response = await axios.post(
    `${process.env.NEXT_PUBLIC_ASSETS_ENDPOINT}/Truck/${vin}/sync`
  );

  return response;
}